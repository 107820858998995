import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'

const News = ({ data }) => (
    <Layout>
    <div className="fixed-top bg-light py-lg-3 text-center">
    <h1 className="d-none d-lg-inline-block mb-0 h2 lishan-xue">Lishan Xue</h1><br />
    <p className="d-none d-lg-inline-block fst-italic title">Pianist</p>
        <nav className="navbar navbar-expand-lg navbar-purple bg-light mx-3 mx-lg-none">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <h1 className="d-lg-none pl-2 h2 fw-bold mobile-title">Lishan Xue<br /><span className="fst-italic h6 fw-normal">Pianist</span></h1>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/">Home | <span className="text-muted">主页</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/bio">About | <span className="text-muted">个人</span></a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="/news">News | <span className="text-muted">新闻</span> <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/concerts">Concerts | <span className="text-muted">演出</span></a>
                    </li>
                    <li className="nav-item dropdown" id="media">
                        <a className="nav-link dropdown-toggle" href="/media" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Media | <span className="text-muted">媒体</span>
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/media/#videos">Videos</a>
                        <a className="dropdown-item" href="/media/#photos">Photos</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/repertoire">Repertoire | <span className="text-muted">常备曲目</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/teaching">Teaching | <span className="text-muted">教学</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact | <span className="text-muted">联系方式</span></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <div className="pt-4 pb-5 masonry container">
    {data.news.nodes.map(node => (
        <div dangerouslySetInnerHTML={{
            __html: node.data.code.childMarkdownRemark.html,
            }} className="news-item" />
    ))}
    </div>
    </Layout>
)
export default News;

export const query = graphql`
{
    news:allAirtable(filter: {data: {publish: {eq: true}}, table: {eq: "News"}}, sort: {order: DESC, fields: data___date}) {
        nodes {
            data {
                code {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
}  
`;